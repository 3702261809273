<template>
  <!-- <b-col cols="12" class="py-24">
    <p class="mb-0 hp-badge-text">
      COPYRIGHT ©2021 Hypeople, All rights Reserved
    </p>
  </b-col> -->
</template>

<script>
import { BCol } from "bootstrap-vue";

export default {
  components: {
    BCol,
  },
};
</script>
